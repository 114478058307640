class ProductAutocomplete {
  constructor(selectors) {
    this.$selectors = selectors;
    this.$cloneProductSelect = $(selectors.selectCloneProductSelector);
  }

  init() {
    this.initializeCloneProductSelect();
  }

  initializeCloneProductSelect() {
    let _this = this;

    function formatResult(data) {
      if (!data.id) { return data.extra; }
      const imageUrl = data.extra.customizer_image || data.extra.default_image_url;
      return $(
        `<div><img width=50 src=${imageUrl} alt=${data.extra.name} /> ${data.extra.name}<br/><small><i>${data.extra.slug}</i></small></div>`
      )
    }

    function truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
      } else {
        return str;
      }
    }

    this.$cloneProductSelect.select2({
      allowClear: true,
      multiple: false,
      placeholder: 'Search by name or slug',
      minimumInputLength: 3,
      templateResult: formatResult,
      ajax: {
        url: Spree.routes.products_api_v2,
        headers: Spree.apiV2Authentication(),
        data: function (params) {
          let query = {
            'filter[name_or_slug_cont]': params.term,
            'clone_product': true,
            'fields[product]': 'name,slug,customizer_image,default_image_url,admin_edit_url',
            'exclude_product_id': _this.$cloneProductSelect.data('excludeProductId')
          }

          return { ...query };
        },
        processResults: function (json) {
          return {
            results: json.data.map(function (obj) {
              return {
                id: obj.id,
                text: truncateString(obj.attributes.name, 50),
                extra: obj.attributes
              }
            })
          }
        }
      }
    });
  }
}

$(function () {
  const selectors = {
    selectCloneProductSelector: '[data-name="select-clone-product"]',
  };
  new ProductAutocomplete(selectors).init();
});
